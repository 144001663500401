import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { configure, CookieConsentProvider, UserAnalyticsProvider } from '@zastrpay/analytics';
import { ErrorBoundary } from '@zastrpay/components';
import { KycRequestProvider } from '@zastrpay/kyc-requests';
import { LayoutProvider } from '@zastrpay/layout';
import { FeatureDetection } from '@zastrpay/pages';
import { ThemeProvider } from '@zastrpay/theme';

import { App } from './App';
import { AppProvider } from './AppProvider';
import './common/api';
import { logError } from './common/application-insights';
import './common/translation';
import { UnhandledError } from './common/UnhandledError';
import { CustomerPermissionsProvider } from './customer/CustomerPermissionsProvider';
import { SessionNavigationProvider } from './layout/SessionNavigationProvider';

const container = document.getElementById('root');

if (!container) {
    throw new Error('Container should be defined');
}

const root = createRoot(container);

configure({ logger: logError });

root.render(
    // <React.StrictMode> // it causes problem because in strict mode all Effects are re-run an extra time.
    <BrowserRouter>
        <ThemeProvider>
            <ErrorBoundary log={logError} fallback={(props) => <UnhandledError {...props} />}>
                <FeatureDetection log={logError}>
                    {/* <AuthenticationProvider> inside AppProvider */}
                    <AppProvider>
                        <CookieConsentProvider>
                            <UserAnalyticsProvider>
                                <SessionNavigationProvider>
                                    <KycRequestProvider prioritize="required">
                                        <CustomerPermissionsProvider>
                                            <LayoutProvider>
                                                <App />
                                            </LayoutProvider>
                                        </CustomerPermissionsProvider>
                                    </KycRequestProvider>
                                </SessionNavigationProvider>
                            </UserAnalyticsProvider>
                        </CookieConsentProvider>
                    </AppProvider>
                    {/* </AuthenticationProvider> inside AppProvider */}
                </FeatureDetection>
            </ErrorBoundary>
        </ThemeProvider>
    </BrowserRouter>,
    // </React.StrictMode>,
);
